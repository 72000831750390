<template lang="pug">
  .wrapper.i-wrap
    .section-left
    .section-mid
      .article-list
        .article-wrap(v-for="article in articles")
          ArticleCard(
            :article="article"
            :cms="true"
          )
    .section-right
      router-link(:to="`/user/article/new`")
        button <i class="fas fa-plus"></i> New Article
</template>

<script>
import mtesCard from '@/components/mtesCard'
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import ArticleCard from '@/components/ArticleCard'
import VClamp from 'vue-clamp'

export default {
  name: 'CmsArticleList',
  components: {
    VClamp,
    mtesCard,
    LeftBar,
    RightBar,
    ArticleCard,
  },
  computed: {

  },
  data(){
    return{
      loading:true,
      articles:[],      

    }
  },
  metaInfo: {
    title: 'Articles - 臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{
    
  },
  async created(){
    var articles = await this.axios.get('/cms/articles')
    this.articles = articles.data;
    this.loading = false;
   
  }
}
</script>
<style lang="scss" scoped>
  .article-list{
    display:flex;
    flex-wrap:wrap;
    
    .article-wrap{
      width:23%;
      margin:1%;
      
    }
  }
</style>
        // z-index: 9;
